/* .ant-slider {
    margin-bottom: 16px;
  }
  
  .slider-handle-color-focus-shadow {
    border-color: brown !important;
    background-color: chartreuse !important;
    color: red !important;
    --antd-wave-shadow-color: red !important;
  }
  
  .ant-slider-handle {
    border-color: brown !important;
    background-color: rgb(15, 15, 15) !important;
  
    --antd-wave-shadow-color: red !important;
  }
  
  .ant-slider-handle:focus {
    border-color: brown !important;
    background-color: rgb(15, 15, 15) !important;
  
    --antd-wave-shadow-color: red !important;
  }
  .ant-slider-handle-dragging {
    border-color: brown !important;
    background-color: chartreuse !important;
    --antd-wave-shadow-color: red !important;
    outline: none;
  }
  
  .ant-slider-handle-click-focused:hover {
    background-color: rgb(0, 0, 0) !important;
    border: rgb(0, 0, 0) !important;
    --antd-wave-shadow-color: red !important;
  }
   */

   /* .ant-slider-rail {
    background-color: #000;
  }
  
  .ant-slider-track {
    background-color: #000;
  }
  
  .ant-slider-handle {
    border-color: #000;
  }
  .ant-skeleton-image{
    width: 100% !important;
   min-height:  200px;
  } */

  .subtitle-editor .ant-skeleton-image{
    width: 100% !important;
    min-height: 200px !important;
  }