 


  .gearloader  .loader {
    width: 48px;
    height: 48px;
    background: #8DC63F;
    display: block;
    margin: 20px auto;
    position: relative;
    box-sizing: border-box;
    animation: rotationBack 1s ease-in-out infinite reverse;
  }
  .gearloader .loader::before {
    content: '';  
    box-sizing: border-box;
    left: 0;
    top: 0;
    transform: rotate(45deg);
    position: absolute;
    width: 48px;
    height: 48px;
    background: #8DC63F;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
  .gearloader .loader::after {
    content: '';  
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    background: #E3D81E;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }